<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-8">
					<a-radio-group :value="filters.status" @change="handleStatusChange">
						<a-radio-button value="0"> Solicitudes </a-radio-button>
						<a-radio-button value="1"> Autorizadas </a-radio-button>
						<a-radio-button value="2"> En sucursal </a-radio-button>
						<a-radio-button value="3"> Entregado </a-radio-button>
					</a-radio-group>
				</div>
				<div class="col-md-4 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Nuevo pedido interno</a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10" v-if="false">
				<div class="col-md-12">
					<a-input-search v-model="searchText" ref="searchInput" placeholder="Ingresa parámetros de búsqueda" enter-button @search="onSearch" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="internalOrdersList" rowKey="id" bordered>
						<div slot="fullName" slot-scope="record" class="text-left">
							{{ record.fullName }}
							<div>{{ moment(record.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</div>
						</div>
						<div slot="description" slot-scope="record" class="text-left">
							<div v-if="record.status > 1 && record.status != 5">
								<div>
									<b>== Autorizado por el cliente ==</b>
									<div v-for="(element, index) in record.impressmentElements.filter((e) => e.confirmed)" :key="`confirmed_${index}`">
										{{ `${element.quantity}` }}x {{ element.measurement_unit == 'piece' ? 'Pieza' : element.measurement_unit == 'liter' ? 'Litro' : 'Servicio' }} - {{ element.description }}
									</div>
								</div>
								<div v-if="record.impressmentElements.filter((e) => e.confirmed == false).length">
									<b>== Rechazado ==</b>
									<div v-for="(element, index) in record.impressmentElements.filter((e) => e.confirmed == false)" :key="`rejected_${index}`">
										{{ `${element.quantity}` }}x {{ element.measurement_unit == 'piece' ? 'Pieza' : element.measurement_unit == 'liter' ? 'Litro' : 'Servicio' }} - {{ element.description }}
									</div>
								</div>
							</div>
							<div v-else>
								<div v-for="(element, index) in record.impressmentElements" :key="index">
									{{ `${element.quantity}` }}x {{ element.measurement_unit == 'piece' ? 'Pieza' : element.measurement_unit == 'liter' ? 'Litro' : 'Servicio' }} - {{ element.description }}
									<a-alert message="Producto agotado o no disponible" banner v-if="!element.available" />
								</div>
							</div>
						</div>
						<div slot="action" slot-scope="record">
							<div>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="onEditRecord(record.id)" v-if="record.status == 0">
									<a-icon type="edit" />
								</button>
								<button class="btn btn-success ant-btn-sm ant-btn-icon-only ml7" @click="openModal(record.id)" v-if="record.status == 1 && allowOperations(record.impressmentElements)">
									<a-icon type="check" />
								</button>
								<button class="btn btn-danger ant-btn-sm ant-btn-icon-only ml7" @click="onDelete(record.id)" v-if="record.status == 0 || record.status == 2 || record.status == 5">
									<a-icon type="close" />
								</button>
								<button class="btn btn-success ant-btn-sm ant-btn-icon-only ml7" @click="completeQuotation(record.id)" v-if="record.status == 2">
									<a-icon type="check" />
								</button>
							</div>
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal :visible="modalView" title="Detalle de Pedido" :closable="false" width="80%" v-if="actualRecord.impressmentElements">
			<template slot="footer">
				<a-button key="back" @click="closeModal"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCloseQuotation"> Confirmar </a-button>
			</template>
			<a-table
				:rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
				:footer="null"
				:columns="columnsElements"
				:data-source="getImpressmentElements"
				:pagination="false"
				class="tableImpressmentElements"
			>
				<div slot="index" slot-scope="record">
					{{ record.index }}
				</div>
				<div slot="quantity" slot-scope="record">
					<div class="text-left">{{ record.quantity }}x {{ record.measurement_unit == 'piece' ? 'pieza' : record.measurement_unit == 'liter' ? 'litro' : 'servicio' }}</div>
				</div>
				<div slot="description" slot-scope="record">
					<div class="text-left">
						{{ record.description }}
						<div v-if="record.link">
							<span class="pointer" @click="onLinkPress(record.link)"><a-icon type="login" /> Abrir link</span>
						</div>
					</div>
				</div>
				<div slot="price" slot-scope="record">{{ numeral(record.price).format('$0,0.00') }}</div>
				<div slot="importe" slot-scope="record">{{ numeral(numeral(record.quantity).value() * numeral(record.price).value()).format('$0,0.00') }}</div>
				<div slot="delivery_time" slot-scope="record">
					{{ record.delivery_time }}
				</div>
			</a-table>
		</a-modal>
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'

export default {
	name: 'InternalOrdersView',
	computed: {
		...mapGetters('internalOrders', ['spinnerLoader', 'spinnerLoaderLabel', 'internalOrdersList', 'filters']),
		...mapGetters('serviceOrders', ['serviceOrdersList']),
		...mapGetters('user', ['user']),
		userPermition() {
			// zem - 17
			// pozos - 11
			// ismael - 1
			let allowedUserPermition = [1, 17]
			// return !!allowedUserPermition.includes(17)
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		getImpressmentElements() {
			return this.actualRecord.impressmentElements
				.filter((e) => e.available)
				.map((e, index) => {
					return {
						...e,
						index: index + 1,
					}
				})
		},
	},
	data() {
		return {
			searchText: '',
			modalQuotationView: false,
			quotationBase64: '',
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Solicitante',
					scopedSlots: { customRender: 'fullName' },
					width: '25%',
				},
				{
					title: 'Solicitud',
					scopedSlots: { customRender: 'description' },
					align: 'center',
					width: '50%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: '15%',
				},
			],
			columnsElements: [
				{
					title: '#',
					align: 'center',
					scopedSlots: { customRender: 'index' },
				},
				{
					title: 'Cantidad',
					align: 'center',
					scopedSlots: { customRender: 'quantity' },
				},
				{
					title: 'Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
				{
					title: 'Precio unitario',
					align: 'center',
					scopedSlots: { customRender: 'price' },
				},
				{
					title: 'Importe',
					align: 'center',
					scopedSlots: { customRender: 'importe' },
				},
				{
					title: 'Tiempo de entrega',
					align: 'center',
					scopedSlots: { customRender: 'delivery_time' },
				},
			],
			modalView: false,
			actualRecord: {},
			selectedRowKeys: [],
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		moment,
		numeral,
		handleStatusChange(e) {
			this.$store.commit('internalOrders/SET_STATE', {
				filters: {
					...this.filters,
					status: e.target.value,
				},
			})
			this.initModule()
		},
		onAddRecord() {
			this.$router.push('/internal-orders/create')
		},
		initModule() {
			this.$store.dispatch('internalOrders/GET', {
				status: this.filters.status,
			})
		},
		onEditRecord(id) {
			this.$router.push(`/internal-orders/${id}`)
		},
		onDelete(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar el registro?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				//
				if (result.isConfirmed) {
					this.$store.dispatch('internalOrders/DELETE', { id })
				}
			})
		},
		downloadQuotation(id) {
			this.$store.dispatch('internalOrders/GENERATE_PDF', id).then((response) => {
				this.quotationBase64 = `data:application/pdf;base64,${response.data.base64}`
				setTimeout(() => {
					this.modalQuotationView = true
				}, 100)
			})
		},
		closeQuotationModal() {
			this.modalQuotationView = false
			this.quotationBase64 = ''
		},
		onCloseQuotation() {
			if (!this.selectedRowKeys.length) {
				Swal.fire({
					title: 'Atención',
					text: 'Debes seleccionar al menos un elemento de la lista',
					icon: 'warning',
					showCancelButton: false,
				})

				return false
			}
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas indicar la llegada de los productos al almacén?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					//
					let selectedRowKeys = []
					this.selectedRowKeys.forEach((index) => {
						selectedRowKeys.push(this.actualRecord.impressmentElements[index])
					})

					await this.$store.dispatch('internalOrders/UPDATE_STATUS', {
						id: this.actualRecord.id,
						status: 2,
						selectedRowKeys,
					})

					this.modalView = false
					this.actualRecord = {}
					this.selectedRowKeys = []
				}
			})
		},
		completeQuotation(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Todos los productos se han entregado?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					//
					this.$store.dispatch('internalOrders/UPDATE_STATUS', {
						id,
						status: 3,
					})
				}
			})
		},
		deliverQuotation(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Todos los productos del pedido se han entregado?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					//
					this.$store.dispatch('internalOrders/UPDATE_STATUS', {
						id,
						status: 4, // Entregados
					})
				}
			})
		},
		onLinkPress(link) {
			window.open(link)
		},
		openModal(id) {
			this.actualRecord = _.cloneDeep(this.internalOrdersList.find((e) => e.id == id))
			let selectedRowKeys = []
			this.actualRecord.impressmentElements.forEach((e, index) => {
				if (e.confirmed) {
					selectedRowKeys.push(index)
				}
			})
			this.modalView = true
			this.selectedRowKeys = _.clone(selectedRowKeys)
		},
		closeModal() {
			this.modalView = false
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		allowOperations(elements) {
			return elements.filter((e) => e.available).length
		},
	},
}
</script>

<style lang="scss">
.tableImpressmentElements {
	td {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
}
</style>